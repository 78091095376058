<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-card class="card">
      <v-card-title class="heading justify-center"
        >DAILY CHECK-IN/CHECK-OUT REPORT</v-card-title
      >
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="12" sm="2">
            <label class="pl-5">From Date</label>

            <br />
            <!-- {{formdata.resedential_premit_issue_date}} -->
            <input
              type="date"
              placeholder="From Date"
              class="example"
              v-model="selectedfromdate"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="pl-5"> TO Date</label>

            <br />
            <!-- {{formdata.resedential_premit_issue_date}} -->
            <input
              type="date"
              placeholder="To Date"
              class="example"
              v-model="selectedtodate"
            />
          </v-col>
           <v-col cols="12" sm="2">
            <label class="pl-5">Series</label>
            <br />
            <v-autocomplete
            v-model="selectedseries"
            outlined
            :items="series_data"
            item-text="name"
            item-value="id"
          
          ></v-autocomplete>
          </v-col>
           <v-col cols="12" sm="2">
            <label class="pl-5">Status</label>
            <br />
           <v-autocomplete
            v-model="selectedstatus"
            outlined
            :items="status_data"
            item-text="name"
            item-value="id"
            
          ></v-autocomplete>
          </v-col>
           <v-col cols="12" sm="2">
            <label class="pl-5">Usertype</label>
            <br />
             <v-autocomplete
            v-model="selectedutype"
            outlined
            :items="utype_data"
            item-text="name"
            item-value="id"
            
          ></v-autocomplete>
          
          </v-col>
           <v-col cols="12" sm="2">
            <label class="pl-5">Select member</label>
            <br />
             <v-combobox
                class="text"
                v-model="selecteduser"
                :items="empmemberlist"
                item-text="displayname"
                item-value="username"
                outlined
                dense
                clearable
                @click="isempselected = false"
                @change="set1(selecteduser, 'emp')"
              ></v-combobox>
            
          </v-col>
            <br />
            <v-col cols="12" sm="4"></v-col>
            <v-col cols="12" sm="4"><center>
            <!-- {{formdata.resedential_premit_issue_date}} -->
            <v-btn class="primary" @click="gethistory"
              >Search<v-icon>mdi-search</v-icon></v-btn
            ></center>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-if="Array.isArray(bookhistory) && bookhistory.length">
      <v-data-table
        :headers="headers"
        :items="bookhistory"
        :search="search"
        class="elevation-1 nowrap"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <h3>ISSUE BOOK DETAILS</h3>
            <br />

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search in table"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <!-- <download-excel
              class="ml-5"
              :data="bookhistory"
              :fields="headers_excel"
              worksheet="Issuebookreport"
              name="IssueBookReport.xls"
            >
              <v-btn color="success" dark class="mb-2">
                Export<v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel> -->
            <excelheader :headers="headers" :tabledata="bookhistory" report_name="DAILY CHECK-IN/CHECK-OUT REPORT">
    </excelheader>
          </v-toolbar>
          <br>
          <span class='ml-4'><strong>Total Count : {{in_count+out_count}} | ISSUE : {{in_count}} | RETURN : {{out_count}} </strong></span>
        </template>
        <template v-slot:item.issue_date="{ item }">
          <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
          <span v-if="item != null && item.issue_date != '' && item.issue_date != null &&item.issue_date != 'null' ">{{
            item.issue_date.split("T")[0].split("-")[2] +
            "-" +
            item.issue_date.split("T")[0].split("-")[1] +
            "-" +
            item.issue_date.split("T")[0].split("-")[0]
          }}</span>
          <span v-else></span>
        </template>
        <template v-slot:item.return_date="{ item }">
          <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
          <span v-if="item != null && item.return_date != '' && item.return_date != null && item.return_date != 'null'">{{
            item.return_date.split("T")[0].split("-")[2] +
            "-" +
            item.return_date.split("T")[0].split("-")[1] +
            "-" +
            item.return_date.split("T")[0].split("-")[0]
          }}</span>
          <span v-else></span>
        </template>
         <template v-slot:item.iscurrent="{ item }">
          <span> {{item.iscurrent}}</span>
          
        </template> 
      </v-data-table>
    </template>
    <v-overlay :value="overlay"> Loading Please Wait.. </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import xlsx_header from "@/components/common_table_header.vue";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  components: {
    "excelheader": xlsx_header
  },
  data: () => ({
    itemlist: [],
    bookhistory:[],
    isempselected:false,
    selecteduser:"",
   selectedseries:"",
   selectedstatus:"",
   selectedutype:"",
   series_data:[],
   status_data:[],
   utype_data:[],
    out_count:'',
    in_count:"",
    seletedbook: "",
    selectedfromdate: null,
    selectedtodate: null,
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    headers: [
      { text: "Sr no", value: "srno" },
      { text: "Accession No", value: "acc_no" },
      // { text: "Isbn No", value: "isbn_no" },
      { text: "Book Title", value: "book_title" },
      { text: "Author", value: "author" },
      { text: "Issue Date", value: "issue_date" },
      { text: "Return Date", value: "return_date" },
      { text: "PRN/Empid", value: "grno_empid" },
      { text: "Borrowing Person", value: "name" },
      { text: "Usertype", value: "usertype" },
      { text: "Status", value: "iscurrent" },
    ],
    headers_excel: {
      "Sr no": "srno",
      "Accession No": "acc_no",
      "Author":"author",
      // "Isbn No": "isbn_no",
      "Book Title": "book_title",
      "Issue Date": "issue_date",
      "Issue Date": {
        field: "issue_date",
        callback: (item) => {
          return item == "" || item == null || item == 'null'
            ? ""
            : item.split("T")[0].split("-")[2] +
                "-" +
                item.split("T")[0].split("-")[1] +
                "-" +
                item.split("T")[0].split("-")[0];
        },
      },
      "Return Date": {
        field: "return_date",
        callback: (item) => {
          return item == "" || item == null || item == 'null'
            ? ""
            : item.split("T")[0].split("-")[2] +
                "-" +
                item.split("T")[0].split("-")[1] +
                "-" +
                item.split("T")[0].split("-")[0];
        },
      },
      "PRN/EMPID": "grno_empid",
      "Borrowing Person": "name",
      Usertype: "usertype",
      "Status": "iscurrent",
      "Total IN": "in_count",
      "Total OUT": "out_count",
    },
  }),

  mounted() {
      this.onLoad();
  },

  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getissuebookdata")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.empmemberlist = res.data.userlist;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.selectedseries = "ALL";
            
            this.status_data.push({ name: "ALL", id: "ALL" });
             this.status_data.push({ name: "Check In", id: "checkIn" });
              this.status_data.push({ name: "Check Out", id: "checkOut" });
            this.selectedstatus = "ALL";
            this.utype_data = res.data.utype_data;
            this.utype_data.push({ name: "ALL", id: "ALL" });
            this.selectedutype = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.overlay = false;
        })
        .finally(() => {
          // var overlay = false;
        });
    },

    set1(value, person) {
      if (person == "emp") {
        this.prn = value.displayname.split(":")[0].trim();
        this.isempselected = true;
      }
    },
    exportexcel() {
      $("#exceltable").table2excel({
        name: "Worksheet Name",
        filename: "DALIY_CHECK-IN/CHECK-OUT_REPORT", //do not include extension
        fileext: ".xls", // file extension
      });
    },
    gethistory() {
      this.overlay = true;
      var params = {
        fromdate: this.selectedfromdate,
        todate: this.selectedtodate,
        series : this.selectedseries,
        status: this.selectedstatus,
        utype:this.selectedutype,
        person : this.prn,
      };
      axios
      .post("/Librarian/getissuereport", params)
      .then((res) => {
         if (res.data.code == "200"){
         this.bookhistory = res.data.bookhistory;
        this.in_count = res.data.in_count;
        this.out_count = res.data.out_count;
          this.overlay = false;
        //console.log(this.bookhistory);
         }
        
      });
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.example {
  margin: 0px;
  background: #ffff;
  border: 1px solid rgb(171, 169, 169);
  border-radius: 5px;
  height: 55px;
  width: 100%;
  transition-duration: 0.2s;
}

.example:focus {
  border-radius: 50px;
  border-color: #2f74b0;
  border-width: 2px;

  outline: 0;
}

.required:after {
  content: " *";
  color: red;
}

.date {
  border: 2px solid red;
}
</style>